import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import CompanyLoader from '../CompanyLoader/CompanyLoader';
import BrokerConfig from '../../../broker-config';
import Link from '../Link/Link';
import './Layout.scss';
import './PoweredBy.scss';
import './ErrorPageLayout.scss';
import CustomLayoutHertz from './CustomLayoutHertz/index';

export function PoweredBy(props) {
	let themeClass = '';
	if (props.theme) {
		themeClass = `powered-by--${props.theme}`;
	}
	return (
		<div className={`powered-by ${themeClass}`}>
			Powered by&nbsp;
			<Link
				className="link link--light"
				href="https://www.carrentalgateway.com"
				target="_blank"
				title="Car Rental Gateway"
			>
				<span className="powered-by__crg">Car Rental Gateway</span>
				<div className="powered-by__logo" />
			</Link>
		</div>
	);
}

PoweredBy.defaultProps = {
	theme: '',
};

PoweredBy.propTypes = {
	theme: PropTypes.string,
};

export function FormLayout(props) {
	const brokerConfig = BrokerConfig.getCurrentBrokerConfig();
	if (brokerConfig.get('template') === 'CustomTemplateHertz') {
		return <CustomLayoutHertz {...props} />;
	}

	let themeClass = '';
	if (props.theme) {
		themeClass = `form-layout--${props.theme}`;
	}
	return (
		<div className={`form-layout ${themeClass}`}>
			<div className="form-layout__container">
				<div className="form-layout__content">
					{props.title && <h1 className="form-layout__heading">{props.title}</h1>}
					{props.loading ? <CompanyLoader /> : props.children}
					<div className="form-layout__footer">
						<PoweredBy theme={props.theme} />
					</div>
				</div>
			</div>
			<div className="form-layout__intro">
				<div className="form-layout__intro-text">{brokerConfig.get('phrases').intro}</div>
				<div className="form-layout__intro-logo" />
			</div>
		</div>
	);
}

FormLayout.defaultProps = {
	children: null,
	loading: false,
	title: '',
	theme: '',
};

FormLayout.propTypes = {
	children: PropTypes.node,
	loading: PropTypes.bool,
	title: PropTypes.string,
	theme: PropTypes.string,
};

export function AbstractLayout(props) {
	const content = (
		<Fragment>
			<div className="abstract-layout__action-container">
				<div className="abstract-layout__button-container">
					{props.buttonNode}
				</div>
				<div className="abstract-layout__link-container">
					{props.linkNode}
				</div>
			</div>
			{props.children}
		</Fragment>
	);

	return (
		<div className="abstract-layout">
			<div className="abstract-layout__background">
				<div className="abstract-layout__content">
					<h1 className="abstract-layout__heading">{props.title}</h1>
					{props.loading ? <CompanyLoader /> : content}
				</div>
			</div>
		</div>
	);
}

AbstractLayout.defaultProps = {
	buttonNode: null,
	children: null,
	linkNode: null,
	loading: false,
};

AbstractLayout.propTypes = {
	title: PropTypes.string.isRequired,
	buttonNode: PropTypes.node,
	children: PropTypes.node,
	linkNode: PropTypes.node,
	loading: PropTypes.bool,
};

export function ErrorPageLayout(props) {
	return (
		<div className="error-page-layout">
			<div className="error-page-layout__picture" />
			<div className="error-page-layout__heading">{props.title}</div>
			<div className="error-page-layout__content">
				{props.children}
			</div>
			<div className="error-page-layout__description">
				{props.description}
			</div>
			<div className="error-page-layout__logo" />
		</div>
	);
}

ErrorPageLayout.defaultProps = {
	title: 'Sorry!',
	description: 'We are working on the problem. Please try again later.',
	children: null,
};

ErrorPageLayout.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.node,
};
