import PropTypes from 'prop-types';
import React from 'react';
import './InputGroup.scss';

export default function Input(props) {
	let themeClass = '';
	if (props.theme) {
		themeClass = `input-group--${props.theme}`;
	}
	return (
		<div className={`input-group ${themeClass} ${props.error && ' input-group--error'}`}>
			<label className="input-group__label" htmlFor={props.id}>{props.label}</label>
			<div className="input-group__inputs">
				{props.inputBeforeAddOn &&
				(
					<div className="input-group__before-addon" {...props.inputBeforeAddOn.props}>
						{props.inputBeforeAddOn.content}
					</div>
				)}
				<div className="input-group__content">
					<div className="input-group__input-container">
						<input className="input-group__input" id={props.id} {...props.inputProps} />
						<div className="input-group__input-outline" />
						{props.inputAddOns.map(addOn => (
							<button className="input-group__addon" type="button" key={addOn.id} {...addOn.props}>
								{addOn.content}
							</button>
						))}
					</div>
					{props.helpText && <div className="input-group__info">{props.helpText}</div>}
					<div className="input-group__error">{props.error}</div>
					{props.inputAfterAddOn &&
					(
						<div className="input-group__after-addon" {...props.inputAfterAddOn.props}>
							{props.inputAfterAddOn.content}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

Input.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	inputProps: PropTypes.object.isRequired,
	error: PropTypes.string,
	helpText: PropTypes.string,
	inputAddOns: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		props: PropTypes.object,
	})),
	inputAfterAddOn: PropTypes.shape({
		content: PropTypes.node,
		props: PropTypes.object,
	}),
	inputBeforeAddOn: PropTypes.shape({
		content: PropTypes.node.isRequired,
		props: PropTypes.object,
	}),
	theme: PropTypes.string,
};

Input.defaultProps = {
	error: '',
	helpText: '',
	inputAddOns: [],
	inputAfterAddOn: null,
	inputBeforeAddOn: null,
	theme: '',
};
