import React from 'react';
import { ReactComponent as Loader } from './loader.svg';
import './CompanyLoader.scss';

export default function CompanyLoader() {
	return (
		<div className="crg-loader">
			<Loader />
		</div>
	);
}
