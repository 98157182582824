import PropTypes from 'prop-types';
import React, { Component } from 'react';
import userService from '../../services/user-service';
import { isCookieCsrfTokenValid } from '../../utils/domain';
import BackToOfficeLink from '../common/BackToOfficeLink/BackToOfficeLink';
import Button from '../common/Button/Button';
import ErrorC from '../common/ErrorC/ErrorC';
import { AbstractLayout } from '../common/Layout/Layout';
import cookies from '../../services/cookies';

class LogoutView extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
	};

	state = {
		loading: false,
		autoRedirect: false,
		error: '',
	};

	componentDidMount() {
		this.tryToLogout();
	}

	tryToLogout = () => {
		const crgLoginCsrfToken = cookies.get('crgLoginCsrfToken');
		if (crgLoginCsrfToken && isCookieCsrfTokenValid(crgLoginCsrfToken)) {
			this.setState({ autoRedirect: true });
			this.destroySession();
		}
	};

	destroySession = () => {
		const { user } = this.props;
		const cookie = cookies.get('crgSessionId');
		this.setState({
			loading: true,
			error: '',
		});
		return userService.endSession(cookie)
			.then(() => {
				user.logout();
			})
			.catch((err) => {
				this.setState({
					error: err.message,
					loading: false,
					autoRedirect: false,
				});
			});
	};

	render() {
		const { loading, error, autoRedirect } = this.state;
		const button = (
			<Button
				type="button"
				onClick={this.destroySession}
				autoFocus
			>Log out
			</Button>
		);
		const back = <BackToOfficeLink title="Back to Office" />;
		const title = autoRedirect ? '' : 'Are you sure you want to log out?';

		return (
			<AbstractLayout
				title={title}
				buttonNode={button}
				linkNode={back}
				loading={loading}
			>
				{error && <ErrorC>{error}</ErrorC>}
			</AbstractLayout>
		);
	}
}

export default LogoutView;
