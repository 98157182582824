import PropTypes from 'prop-types';
import React, { Component } from 'react';
import userService from '../../services/user-service';
import { getRandomBytesBase64 } from '../../utils/crypto';
import { handleCallbackRedirect, pushToLogAsUsers } from '../../utils/domain';
import BrokerConfig from '../../broker-config';
import ErrorC from '../common/ErrorC/ErrorC';
import Input from '../common/InputGroup/InputGroup';
import { FormLayout } from '../common/Layout/Layout';
import ChangePassword from './ChangePassword';
import Recaptcha from './Recaptcha';
import Tfa from './Tfa';
import Button from '../common/Button/Button';
import cookies from '../../services/cookies';

const capabilities = Object.freeze(['Tfa', 'Finish', 'ChangePassword', 'TfaCookie', 'Recaptcha2']);

class LoginView extends Component {
	static isSupportView() {
		return /^\/support\/?$/.test(window.location.pathname) ||
			/^\/switchUser\/?$/.test(window.location.pathname);
	}

	static propTypes = {
		user: PropTypes.object,
	};

	static defaultProps = {
		user: undefined,
	};

	static getTitle(step) {
		switch (step) {
			case 'ChangePassword':
				return 'Change password';
			default:
				return 'Log in';
		}
	}

	constructor() {
		super();
		this.url = new URL(window.location.href);
		this.state = {
			authStep: '',
			error: '',
			loading: false,
			logAs: this.username,
			partialAuthToken: '',
			captchaKey: '',
			password: '',
			username: '',
			usernameError: '',
			passwordError: '',
		};
	}

	componentDidMount() {
		this.redirect(this.props.user);
	}

	get usernameFocus() {
		if (!LoginView.isSupportView()) {
			return true;
		} else if (LoginView.isSupportView() && this.username) {
			return true;
		}
		return false;
	}

	get username() {
		return this.url.searchParams.get('username') || '';
	}

	get isTitaniumRequired() {
		return this.url.searchParams.get('requireTitanium') === '1';
	}

	redirect = (user) => {
		if (!user) {
			return;
		}
		let callBackUrl = this.url.searchParams.get('callbackUrl');
		/**
		 * If no callback url is provided, check broker
		 * config for callback url
		 */
		if (!callBackUrl) {
			callBackUrl = BrokerConfig.getCurrentBrokerConfig().get('callbackUrl') || '';
		}

		this.setState({ loading: true });

		if (!this.isTitaniumRequired || user.topAgencyId === undefined) {
			handleCallbackRedirect(decodeURIComponent(callBackUrl));
			return;
		}

		if (user.isTitaniumUser) {
			const urlCopy = new URL('/switchUser', window.location);
			urlCopy.search = window.location.search;
			urlCopy.searchParams.set('username', user.via.username);
			urlCopy.searchParams.delete('requireTitanium');
			window.location.replace(urlCopy);
		} else {
			handleCallbackRedirect();
		}
	};

	authViaDelegated = (res) => {
		const { logAs, username } = this.state;
		const user = logAs || username;

		userService.withCredentials({ sessionId: res.sessionId })
			.authViaDelegatedAccess(user, { forResults: ['SessionId'] })
			.then((data) => {
				if (logAs !== username) {
					pushToLogAsUsers(logAs);
				}
				this.success(data);
			})
			.catch(this.error);
	};

	authViaPassword = () => {
		const { username, password } = this.state;
		this.setState({
			loading: true,
			error: '',
		});
		return userService.authViaPassword(username, password, {
			forResults: ['SessionId'],
			capabilities,
		});
	};

	finishStep = (partialAuthToken) => {
		this.setState({ loading: true });
		userService.authStepFinish(partialAuthToken, { forResults: ['SessionId'] })
			.then((res) => {
				if (LoginView.isSupportView()) {
					this.authViaDelegated(res);
					return;
				}
				this.success(res);
			})
			.catch(this.error);
	};

	handleChange = (e) => {
		if (
			(e.target.name === 'password' || e.target.name === 'username') &&
			e.target.value.length
		) {
			const target = e.target.name + 'Error';
			this.setState({ [target]: '' });
		}
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { username, password } = this.state;
		const error = 'Please fill out this field';
		this.setState(prevState => ({
			usernameError: username.length ? '' : error,
			passwordError: password.length ? '' : error,
			error: (!username.length || !password.length) ? '' : prevState.error,
		}));
		if (!username.length || !password.length) {
			return;
		}

		this.authViaPassword()
			.then((res) => {
				if (res.partial) {
					this.handleAuthSteps(res);
				} else if (this.state.logAs && LoginView.isSupportView()) {
					this.authViaDelegated(res);
				} else {
					this.success(res);
				}
			})
			.catch(this.error);
	};

	handleAuthSteps = (res) => {
		const state = {
			loading: false,
			partialAuthToken: res.partialAuthToken,
		};
		switch (res.nextStep) {
			case 'Finish':
				this.finishStep(res.partialAuthToken);
				break;
			case 'Tfa':
				this.setState({ ...state, authStep: 'Tfa' });
				break;
			case 'ChangePassword':
				this.setState({ ...state, authStep: 'ChangePassword' });
				break;
			case 'Recaptcha2':
				this.setState({ ...state, authStep: 'Recaptcha2', captchaKey: res.nextStepParams.siteKey });
				break;
			default:
				this.error(new Error('Invalid authentication step.'));
		}
	};

	tryAgain = (message) => {
		this.setState({
			authStep: '',
			error: message || '',
			loading: false,
			partialAuthToken: '',
			password: '',
		});
	};

	error = (err) => {
		cookies.remove('crgSessionId', {
			domain: process.env.REACT_APP_ROOT_DOMAIN,
		});
		cookies.remove('crgLoginCsrfToken', {
			domain: process.env.REACT_APP_ROOT_DOMAIN,
		});
		this.setState({
			authStep: '',
			error: err.message,
			loading: false,
		});
	};

	success = (res) => {
		cookies.set('crgLoginCsrfToken', getRandomBytesBase64(20), {
			domain: process.env.REACT_APP_ROOT_DOMAIN,
		});
		cookies.set('crgSessionId', res.sessionId, {
			domain: process.env.REACT_APP_ROOT_DOMAIN,
		});
		this.redirect(res.info);
	};

	render() {
		const {
			username,
			password,
			loading,
			error,
			logAs,
			authStep,
			partialAuthToken,
			captchaKey,
			usernameError,
			passwordError,
		} = this.state;

		const brokerConfig = BrokerConfig.getCurrentBrokerConfig();
		const theme = brokerConfig.get('theme');

		return (
			<FormLayout
				loading={loading}
				title={LoginView.getTitle(authStep)}
				theme={theme}
			>
				{(authStep === 'Tfa' && !loading) && (
					<Tfa
						capabilities={capabilities}
						handleNextStep={this.handleAuthSteps}
						partialAuthToken={partialAuthToken}
						tryAgain={this.tryAgain}
						theme={theme}
					/>
				)}
				{(authStep === 'ChangePassword' && !loading) && (
					<ChangePassword
						capabilities={capabilities}
						username={username}
						handleNextStep={this.handleAuthSteps}
						partialAuthToken={partialAuthToken}
						tryAgain={this.tryAgain}
						theme={theme}
					/>
				)}
				{(authStep === 'Recaptcha2' && !loading) && (
					<Recaptcha
						capabilities={capabilities}
						captchaKey={captchaKey}
						handleNextStep={this.handleAuthSteps}
						partialAuthToken={partialAuthToken}
						tryAgain={this.tryAgain}
					/>
				)}
				{(authStep === '' && !loading) && (
					<form onSubmit={this.handleSubmit}>
						{LoginView.isSupportView() && (
							<Input
								id="logAs"
								label="Log in as"
								name="logAs"
								inputProps={{
									value: logAs,
									readOnly: this.username,
									name: 'logAs',
									autoFocus: true,
									onChange: this.handleChange,
								}}
							/>
						)}
						<Input
							id="username"
							label="Username"
							inputProps={{
								value: username,
								name: 'username',
								autoFocus: this.usernameFocus,
								onChange: this.handleChange,
							}}
							error={usernameError}
							theme={theme}
						/>
						<Input
							id="password"
							label="Password"
							inputProps={{
								value: password,
								name: 'password',
								type: 'password',
								onChange: this.handleChange,
							}}
							error={passwordError}
							theme={theme}
						/>
						<Button type="submit" theme={theme}>Log in</Button>

						<div className="form-layout__error-container">
							{error && <ErrorC>{error}</ErrorC>}
						</div>
					</form>
				)}
			</FormLayout>
		);
	}
}

export default LoginView;
