import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import userService from '../../services/user-service';
import { loadJsToHeader } from '../../utils/fileLoader';
import CompanyLoader from '../common/CompanyLoader/CompanyLoader';
import ErrorC from '../common/ErrorC/ErrorC';

const captchaArray = [];
let captchaScripLoaded = false;

window.onloadRecaptchaCallback = () => {
	captchaArray.forEach((compoent) => {
		compoent.initCaptcha();
	});
};

class Recaptcha extends Component {
	static propTypes = {
		capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
		captchaKey: PropTypes.string.isRequired,
		handleNextStep: PropTypes.func.isRequired,
		partialAuthToken: PropTypes.string.isRequired,
		tryAgain: PropTypes.func.isRequired,
	};

	state = {
		error: '',
		loading: false,
	};

	componentDidMount() {
		if (captchaScripLoaded) {
			this.initCaptcha();
			return;
		}

		captchaArray.push(this);
		loadJsToHeader('https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback&render=explicit', {
			async: true,
			defer: true,
			onload: () => {
				captchaScripLoaded = true;
			},
			onerror: () => {
				this.props.tryAgain('Failed to load captcha. Please try again');
			},
		});
	}

	initCaptcha = () => {
		window.grecaptcha.render(this.props.captchaKey, {
			'sitekey': this.props.captchaKey,
			'callback': this.authViaCaptcha,
		});
	};

	authViaCaptcha = (successToken) => {
		this.setState({
			loading: true,
			error: '',
		});

		const {
			partialAuthToken,
			capabilities,
			handleNextStep,
		} = this.props;

		userService.authStepRecaptcha2(
			partialAuthToken,
			successToken,
			{ capabilities },
			this.state.captchaResponse,
		).then(handleNextStep)
			.catch(this.error);
	};

	error = (err) => {
		if (err.name === 'InvalidPartialAuthTokenError') {
			err.message = 'Your session has expired. Please login again.';
		}
		this.props.tryAgain(err.message);
	};

	render() {
		const {
			error,
			loading,
		} = this.state;

		return (
			<form onSubmit={this.authViaCaptcha}>
				{loading ? <CompanyLoader /> : (
					<Fragment>
						<div className="form-layout__captcha-confirm">Please verify that you are not a robot.</div>
						<div id={this.props.captchaKey} />
						<div className="form-layout__error-container">
							{error && <ErrorC>{error}</ErrorC>}
						</div>
					</Fragment>
				)}
			</form>
		);
	}
}

export default Recaptcha;
