import React from 'react';
import { Link } from 'react-router-dom';
import BackToOfficeLink from '../common/BackToOfficeLink/BackToOfficeLink';
import { FormLayout } from '../common/Layout/Layout';

import './NotFoundView.scss';

export default function NotFoundView() {
	return (
		<FormLayout>
			<div className="not-found">
				<div className="not-found__heading">Sorry! </div>
				<div className="not-found__content">We can&#39;t seem to find the page you are looking for</div>
				<div className="not-found__list-heading">You might been looking for:</div>
				<ul className="not-found__list">
					<li className="not-found__list-item"><Link to="/" className="link">Login</Link></li>
					<li className="not-found__list-item"><BackToOfficeLink /></li>
				</ul>
			</div>
		</FormLayout>
	);
}
