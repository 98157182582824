import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import userService from '../../services/user-service';
import Button from '../common/Button/Button';
import ErrorC from '../common/ErrorC/ErrorC';
import CompanyLoader from '../common/CompanyLoader/CompanyLoader';
import Input from '../common/InputGroup/InputGroup';

class ChangePassword extends Component {
	static propTypes = {
		capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
		handleNextStep: PropTypes.func.isRequired,
		partialAuthToken: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		tryAgain: PropTypes.func.isRequired,
		theme: PropTypes.string,
	};

	static defaultProps = {
		theme: '',
	};

	state = {
		error: '',
		loading: false,
		password: '',
		repeatPassword: '',
		passwordErrors: {
			isPasswordValid: false,
			isPasswordMatch: false,
		},
		isShowPasswordErrors: false,
		showPasswords: false,
	};

	componentDidMount() {
		this.setupPasswordValidator();
	}

	setupPasswordValidator() {
		this.passwordValidator = null;
		userService
			.createPasswordValidator()
			.then((passwordValidator) => {
				this.passwordValidator = passwordValidator;
			})
			.catch(() => {
				// pass
			});
	}

	getPasswordAfterAddon() {
		if (!this.state.passwordErrors.isPasswordValid) {
			return null;
		}
		return {
			content: <span style={{ color: '#3D8D00' }}>OK</span>,
		};
	}

	getPasswordAddons() {
		const content = this.state.showPasswords ? 'hide' : 'show';

		return [
			{
				id: 'showHide',
				content,
				props: {
					onClick: () => {
						this.setState(prevState => ({
							showPasswords: !prevState.showPasswords,
						}));
					},
				},
			},
		];
	}

	getPasswordHelpText() {
		if (
			!this.state.passwordErrors.isPasswordValid &&
			!this.state.isShowPasswordErrors
		) {
			return 'Must be at least 10 characters, must contain ' +
				'upper-case, lower-case, number.';
		}
		return '';
	}

	getPasswordErrorText() {
		if (
			!this.state.passwordErrors.isPasswordValid &&
			this.state.isShowPasswordErrors
		) {
			return 'Must be at least 10 characters, must contain ' +
				'upper-case, lower-case, number.';
		}
		return '';
	}

	getRepeatPasswordErrorText() {
		if (
			!this.state.passwordErrors.isPasswordMatch &&
			this.state.isShowPasswordErrors &&
			this.state.passwordErrors.isPasswordValid
		) {
			return 'Password doesn\'t match';
		}
		return '';
	}

	handleChange = (e) => {
		let { password, repeatPassword, isShowPasswordErrors } = this.state;
		const { name, value } = e.target;
		if (name === 'password') {
			password = value;
		}
		if (name === 'repeatPassword') {
			repeatPassword = value;
		}
		const passwordErrors = this.validatePassword(password, repeatPassword);
		if (passwordErrors.isPasswordValid) {
			isShowPasswordErrors = false;
		}
		this.setState({
			[name]: value,
			passwordErrors,
			isShowPasswordErrors,
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const { password, repeatPassword } = this.state;
		const passwordErrors = this.validatePassword(password, repeatPassword);
		if (
			!passwordErrors.isPasswordValid ||
			!passwordErrors.isPasswordMatch
		) {
			this.setState({
				passwordErrors,
				isShowPasswordErrors: true,
			});
		} else {
			this.changePassword(password);
		}
	};

	changePassword = (password) => {
		this.setState({ loading: true });
		const { partialAuthToken, handleNextStep, capabilities } = this.props;
		userService.authStepChangePassword(partialAuthToken, password, {
			capabilities,
		})
			.then(handleNextStep)
			.catch(this.error);
	};

	error = (err) => {
		if (err.name === 'InvalidPartialAuthTokenError') {
			this.props.tryAgain('Your session has expired. Please login again.');
			return;
		}

		this.setState({
			error: err.message,
			loading: false,
		});
	};

	validatePassword(password, passwordAgain) {
		const passwordErrors = {
			isPasswordValid: false,
			isPasswordMatch: false,
		};
		if (typeof this.passwordValidator === 'function') {
			passwordErrors.isPasswordValid = this.passwordValidator(password);
		}
		passwordErrors.isPasswordMatch = password === passwordAgain;
		return passwordErrors;
	}

	render() {
		const {
			error,
			loading,
			password,
			repeatPassword,
			showPasswords,
		} = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				{loading ? <CompanyLoader /> : (
					<Fragment>
						<Input
							id="username"
							label="Username"
							inputProps={{
								value: this.props.username,
								type: 'text',
								readOnly: true,
							}}
							theme={this.props.theme}
						/>
						<Input
							id="password"
							label="New password"
							error={this.getPasswordErrorText()}
							helpText={this.getPasswordHelpText()}
							inputProps={{
								name: 'password',
								value: password,
								type: showPasswords ? 'text' : 'password',
								autoFocus: true,
								onChange: this.handleChange,
								autoComplete: 'new-password',
							}}
							inputAfterAddOn={this.getPasswordAfterAddon()}
							inputAddOns={this.getPasswordAddons()}
							theme={this.props.theme}
						/>
						<Input
							id="repeatPassword"
							label="Repeat new password"
							error={this.getRepeatPasswordErrorText()}
							inputProps={{
								name: 'repeatPassword',
								value: repeatPassword,
								type: showPasswords ? 'text' : 'password',
								onChange: this.handleChange,
								autoComplete: 'new-password',
							}}
							inputAddOns={this.getPasswordAddons()}
							theme={this.props.theme}
						/>
						<Button theme={this.props.theme}>Change</Button>
						<div className="form-layout__error-container">
							{error && <ErrorC>{error}</ErrorC>}
						</div>
					</Fragment>
				)}
			</form>
		);
	}
}

export default ChangePassword;
