import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ErrorPageLayout } from '../common/Layout/Layout';

class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.node,
	};

	static defaultProps = {
		children: null,
	};

	state = {
		isError: false,
	};

	componentDidCatch(error, info) {
		// eslint-disable-next-line no-console
		console.error(error, info.componentStack);
		this.setState({ isError: true });
	}

	render() {
		if (!this.state.isError) {
			return this.props.children;
		}
		return (
			<ErrorPageLayout>
				The page you were looking for is temporarily not available
			</ErrorPageLayout>
		);
	}
}

export default ErrorBoundary;
