import PropTypes from 'prop-types';
import React from 'react';
import Link from '../Link/Link';

export default function BackToOfficeLink(props) {
	return (
		<Link href={process.env.REACT_APP_DEFAULT_REDIRECT_URL}>
			{props.title}
		</Link>
	);
}

BackToOfficeLink.defaultProps = {
	title: 'Office',
};

BackToOfficeLink.propTypes = {
	title: PropTypes.string,
};
