import PropTypes from 'prop-types';
import React from 'react';
import './ErrorC.scss';

export default function ErrorC(props) {
	return (
		<div className="error">
			{props.children}
		</div>
	);
}

ErrorC.propTypes = {
	children: PropTypes.node.isRequired,
};
