import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Routes from './components/Routes/Routes';
import userService from './services/user-service';

import './index.scss';

userService.configure({
	baseUrl: process.env.REACT_APP_USER_SERVICE_URL,
});

ReactDOM.render(
	<ErrorBoundary>
		<Routes />
	</ErrorBoundary>,
	window.document.getElementById('root'),
);

window.isReactBundleLoaded = true;
