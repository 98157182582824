import cookies from '../services/cookies';

/**
 * Validates sub domain against parent domain.
 *
 * @param {string} parent - Parent to validate against.
 * @param {string} sub - Sub domain to validate.
 * @return {boolean}
 */
export function _isSubDomain(parent, sub) {
	if (!parent || !sub) {
		return false;
	}
	const parentLabels = parent.split('.');
	const subLabels = sub.split('.');
	while (parentLabels.length > 0) {
		if (!subLabels.length) {
			return false;
		}
		if (parentLabels.pop() !== subLabels.pop()) {
			return false;
		}
	}
	return true;
}

/**
 * Validates csrf token in cookie and url.
 *
 * @param {string} cookieCsrfToken
 * @return {boolean}
 */
export function isCookieCsrfTokenValid(cookieCsrfToken) {
	const csrfToken = new URL(window.location.href).searchParams.get('csrfToken');
	return cookieCsrfToken === csrfToken;
}

/**
 * Redirects to callbackUrl or to default url which is specified in config.
 *
 * @param {string} [callbackUrl]
 */
export function handleCallbackRedirect(callbackUrl) {
	let url = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
	try {
		const callbackUrlHost = new URL(callbackUrl).hostname;
		if (_isSubDomain(process.env.REACT_APP_ROOT_DOMAIN, callbackUrlHost)) {
			url = callbackUrl;
		}
	} catch (err) {
		// Default redirect
	}
	window.location.replace(url);
}

/**
 * Push user to latest log as users by support and store list as cookie
 *
 * @param {string} username
 */
export function pushToLogAsUsers(username) {
	if (!username) {
		return;
	}

	const days = 32;
	const logAsUsersCookie = cookies.get('crgLogAsUsers');
	let logAsUsers = [];

	if (logAsUsersCookie) {
		try {
			logAsUsers = JSON.parse(logAsUsersCookie);
		} catch (err) {
			// do nothing
		}
	}

	const currentPos = logAsUsers.indexOf(username);

	if (currentPos !== -1) {
		logAsUsers.splice(currentPos, 1);
	}

	logAsUsers.unshift(username);

	if (logAsUsers.length > 5) {
		logAsUsers = logAsUsers.slice(0, 5);
	}

	cookies.set('crgLogAsUsers', JSON.stringify(logAsUsers), {
		expires: days,
		domain: process.env.REACT_APP_ROOT_DOMAIN,
	});
}
