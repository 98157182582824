class BrokerConfig {
	constructor(data) {
		Object.assign(this, data);
		this.availableConfigs = Object.keys(data);
		this.defaultConfig = 'default';
	}

	/**
	 * Returns broker config name, if none exists returns empty string
	 * @returns {string}
	 */
	getCurrentBrokerName() {
		const path = window.location.pathname;
		const config = this.availableConfigs.find(el => path.includes(el));
		if (config) {
			return config;
		}
		return '';
	}

	/**
	 * Returns current broker config object
	 *
	 * @returns {Object}
	 */
	getCurrentBrokerConfig() {
		const configName = this.getCurrentBrokerName() || this.defaultConfig;
		return this[configName];
	}
}

export default new BrokerConfig({
	default: new Map([
		['phrases', { intro: 'One-stop-shop for distribution of mobility services' }],
	]),
	godryv: new Map([
		['phrases', { intro: 'Rental cars from the global leaders in car rental' }],
		['theme', 'godryv'],
	]),
	'gsa-hertz': new Map([
		['template', 'CustomTemplateHertz'],
		['theme', 'hertz'],
		['callbackUrl', `${process.env.REACT_APP_DEFAULT_REDIRECT_URL}/booking-visual`],
	]),
});
