import PropTypes from 'prop-types';
import React from 'react';
import './Link.scss';

export default function Link(props) {
	let themeClass = '';
	if (props.theme) {
		themeClass = `link--${props.theme}`;
	}

	return (
		<a className={`link ${themeClass}`} {...props}>
			{props.children}
		</a>
	);
}

Link.propTypes = {
	children: PropTypes.node.isRequired,
	theme: PropTypes.string,
};

Link.defaultProps = {
	theme: '',
};
