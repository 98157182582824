import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

export default function Button(props) {
	let themeClass = '';
	if (props.theme) {
		themeClass = `button--${props.theme}`;
	}
	return (
		<button className={`button ${themeClass}`} {...props}>
			{props.children}
		</button>
	);
}

Button.propTypes = {
	children: PropTypes.node.isRequired,
	theme: PropTypes.string,
};

Button.defaultProps = {
	theme: '',
};
