/**
 * Replaces base64 character with url safe character.
 *
 * @param c - Base64 character
 * @return {string}
 */
function base64SafeUrlCharacter(c) {
	switch (c) {
		case '/':
			return '_';
		case '+':
			return '-';
		default:
			return '';
	}
}

/**
 * Generates random Base16 string.
 *
 * @param {number} length - Byte count
 * @return {string}
 */
export function getRandomBytesBase64(length) {
	const crypto = window.crypto || window.msCrypto; // for IE 11
	const arr = new Uint8Array((length || 40) / 2);
	crypto.getRandomValues(arr);
	return window.btoa(arr.toString()).replace(/[/+]|=+/g, base64SafeUrlCharacter);
}
