import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import userService from '../../services/user-service';
import Button from '../common/Button/Button';
import ErrorC from '../common/ErrorC/ErrorC';
import Input from '../common/InputGroup/InputGroup';
import CompanyLoader from '../common/CompanyLoader/CompanyLoader';
import cookies from '../../services/cookies';

import mobileImageScr from './mobile.svg';

class Tfa extends Component {
	static propTypes = {
		handleNextStep: PropTypes.func.isRequired,
		partialAuthToken: PropTypes.string.isRequired,
		capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
		tryAgain: PropTypes.func.isRequired,
		theme: PropTypes.string,
	};

	static defaultProps = {
		theme: '',
	};

	state = {
		code: '',
		error: '',
		loading: false,
	};

	componentDidMount() {
		if (!cookies.get('crgTfaToken')) {
			return;
		}

		const { handleNextStep, partialAuthToken, capabilities } = this.props;
		this.setState({ loading: true });

		userService.authStepTfa(partialAuthToken, undefined, { capabilities, cookie: cookies.get('crgTfaToken') })
			.then((handleNextStep))
			.catch(() => {
				cookies.remove('crgTfaToken');
				this.setState({ loading: false });
			});
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { code } = this.state;
		const { handleNextStep, partialAuthToken, capabilities } = this.props;
		this.setState({
			loading: true,
			error: '',
		});
		userService.authStepTfa(partialAuthToken, code, { capabilities })
			.then((res) => {
				if (res.cookie !== undefined) {
					cookies.set('crgTfaToken', res.cookie.value, { expires: new Date(res.cookie.expires) });
				}
				handleNextStep(res);
			})
			.catch(this.error);
	};

	error = (err) => {
		if (err.name === 'InvalidPartialAuthTokenError') {
			this.props.tryAgain('Your session has expired. Please login again.');
			return;
		}

		if (err.name === 'InvalidTfaCodeError') {
			err.message = 'Invalid code';
		}

		this.setState({
			error: err.message,
			loading: false,
		});
	};

	render() {
		const { code, loading, error } = this.state;
		const mobileImage = (
			<img
				width="28px"
				height="100%"
				src={mobileImageScr}
				alt="mobile"
			/>
		);

		return (
			<form onSubmit={this.handleSubmit}>
				{loading ? <CompanyLoader /> : (
					<Fragment>
						<Input
							id="tfa"
							label="Enter the 6-digit code displayed in the Google Authenticator application:"
							inputProps={{
								name: 'code',
								value: code,
								autoFocus: true,
								onChange: this.handleChange,
								maxLength: '6',
								autoComplete: 'off',
							}}
							inputBeforeAddOn={{
								content: mobileImage,
							}}
							theme={this.props.theme}
						/>
						<Button theme={this.props.theme}>Log in</Button>
						<div className="form-layout__error-container">
							{error && <ErrorC>{error}</ErrorC>}
						</div>
					</Fragment>
				)}
			</form>
		);
	}
}

export default Tfa;
