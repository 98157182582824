import React from 'react';
import PropTypes from 'prop-types';
import CompanyLoader from '../../CompanyLoader/CompanyLoader';
import Link from '../../Link/Link';
import { PoweredBy } from '../Layout';

import './style.scss';

const CustomLayoutHertz = props => (
	<div className="custom-layout">
		<div className="custom-layout__header">
			<div className="custom-layout__header-logo" />
			<div className="custom-layout__header-slogan" />
		</div>
		<div className="custom-layout__container">
			<div className="custom-layout__content">
				<div className="custom-layout__content-wrapper">
					{props.title && <h1 className="custom-layout__heading">{props.title}</h1>}
					<div className="custom-layout__form">
						{props.loading ? <CompanyLoader /> : props.children}
					</div>
				</div>
			</div>
			<div className="custom-layout__intro" />
		</div>
		<div className="custom-layout__footer">
			<div className="custom-layout__footer-container">
				<div className="custom-layout__footer-address">
					<div className="custom-layout__footer-heading">
						ITSC, GSA Hertz Car Rental
					</div>
					<div>Nieuwe Spiegelstraat 18</div>
					<div>1406SJ Bussum </div>
					<div>The Netherlands</div>
				</div>
				<div className="custom-layout__contact">
					<div className="custom-layout__footer-heading">
						Helpdesk
					</div>
					<div className="custom-layout__footer-email">
						<Link theme={props.theme} href="mailto:reservations@itsc.nl">E-mail: reservations@itsc.nl</Link>
					</div>
					<div className="custom-layout__contacts">
						<div>
							<Link theme={props.theme} href="tel:+498005895132">DE: +49 (0) 8005 895 132</Link>
							<Link theme={props.theme} href="tel:+31356970560">NL: +31 (0) 35 6970560</Link>
							<Link theme={props.theme} href="tel:+41435880440">CH: +41 (0) 435 880 440</Link>
						</div>
						<div>
							<Link theme={props.theme} href="tel:+442035145996">UK: +44 (0) 203 514 5996</Link>
							<Link theme={props.theme} href="tel:+3227020666">BE: +32 (0) 2 702 0666</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="custom-layout__crg">
				<PoweredBy theme={props.theme} />
			</div>
		</div>
	</div>
);

CustomLayoutHertz.defaultProps = {
	children: null,
	loading: false,
	title: '',
	theme: '',
};

CustomLayoutHertz.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	theme: PropTypes.string,
	loading: PropTypes.bool,
};

export default CustomLayoutHertz;
